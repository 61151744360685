import React from 'react';
import { Link } from 'gatsby';

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "./tags-page.scss";
import { slugify } from "../../util/utilityFunctions";

const TagsPage = ({ pageContext }) => {
    const { tags, tagPostCounts } = pageContext;

    return (
        <Layout>
            <SEO/>
            <div className="tags-page-wrapper">
                <ul>
                    {tags.map(tag => (
                        <li key={tag}>
                            
                                <div className="tag">
                                <Link className="link-tag" to={`/tags/${slugify(tag)}`}>
                                    {tag} 
                                    </Link>
                                    <div className="tag-count">
                                        {tagPostCounts[tag]}
                                    </div>
                                </div>
                            
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default TagsPage